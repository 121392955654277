<template>
  <div class="report-records-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-4">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="datatable.queries.per_page" class="ml-1 mr-1" size="sm"
                      :options="[10, 15, 25, 50, 100]" @change="getRecords"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-8">
                <div class="dataTables_filter text-md-right d-flex justify-content-end align-items-center">
                  <div class="d-inline-block">
                    <date-range-picker ref="picker" v-model="filter.dateRange" :opens="filter.opens"
                      :locale-data="{ firstDay: 1, format: 'mmm dd, yyyy' }" :min-date="filter.minDate"
                      :max-date="filter.maxDate" :single-date-picker="filter.singleDatePicker"
                      :time-picker="filter.timePicker" :time-picker24-hour="filter.timePicker24Hour"
                      :show-week-numbers="filter.showWeekNumbers" :show-dropdowns="filter.showDropdowns"
                      :auto-apply="filter.autoApply" :always-show-calendars="filter.alwaysShowCalendars"
                      :linked-calendars="filter.linkedCalendars" :ranges="filter.ranges" @update="filterDateUpdateValues">
                    </date-range-picker>
                  </div>
                  <div class="d-inline-block mx-2" style="min-width: 300px">
                    <multiselect
                      v-model="businessSelected"
                      :options="businesses"
                      placeholder="Select Business"
                      label="name"
                      :allow-empty="false"
                      :show-labels="false"
                    ></multiselect>
                  </div>
                  <button class="ml-1 btn btn-primary btn-sm" @click="refresh">
                    <i class="uil uil-refresh"></i>
                  </button>
                  <a class="btn btn-primary btn-sm ml-2 mr-2" @click="handleExport" >
                    <i class="uil uil-download-alt"></i> Export
                  </a>
                </div>
              </div>
            </div>
            <template v-if="!loading">
              <div class="table-responsive mb-0">
                <b-table :items="records.data" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc" :per-page="datatable.queries.per_page"
                  :no-local-sorting="true" @sort-changed="sortChanged">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | moment('MM-DD-YYYY hh:mm:ss A') }}
                  </template>
                  <template v-slot:cell(cost)="data">
                    {{ data.item.cost ? `$${data.item.cost.toFixed(4)}` : '-' }}
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalRecords }} records
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="datatable.queries.page" :total-rows="totalRecords"
                        :per-page="datatable.queries.per_page" @change="onPageChanged"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment-timezone'
import Multiselect from 'vue-multiselect'
import axios from 'axios'

export default {
  components: {
    DateRangePicker, Multiselect,
  },
  data() {
    return {
      datatable: {
        columns: [
          { key: 'name', label: 'Business', sortable: false },
          { key: 'sms_in', label: 'SMS In', sortable: false },
          { key: 'sms_out', label: 'SMS Out', sortable: false },
          { key: 'mms_in', label: 'MMS In', sortable: false },
          { key: 'mms_out', label: 'MMS Out', sortable: false },
          { key: 'segments', label: 'Segments', sortable: false },
          { key: 'email_out', label: 'Email Out', sortable: false },
          { key: 'call_in', label: 'Call Seconds In', sortable: false },
          { key: 'sms_charge', label: 'SMS Charge', sortable: false },
          { key: 'mms_charge', label: 'MMS Charge', sortable: false },
          { key: 'call_charge', label: 'Call Charge', sortable: false },
          { key: 'carrier_fee', label: 'Carrier Charge', sortable: false },
          { key: 'number_fee', label: 'Number Charge', sortable: false },
          { key: 'total_charge', label: 'Total Charge', sortable: false },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          search_type: null,
          sort_by: 'id',
          sort_desc: true,
          business_id: null,
          start_at: null,
          end_at: null,
        },
      },
      records: [],
      loading: false,
      businesses: [],
      businessSelected: null,
      filter: {
        opens: 'left',
        minDate: null,
        maxDate: null,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        dateRange: {
          startDate: moment().subtract(30, 'days').toDate(),
          endDate: moment().toDate(),
        },
        linkedCalendars: true,
        ranges: {
          'Today': [moment().toDate(), moment().toDate()],
          'Last 7 Days': [moment().subtract(7, 'days').toDate(), moment().toDate()],
          'Last 30 Days': [moment().subtract(30, 'days').toDate(), moment().toDate()],
          'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
          'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
        },
        alwaysShowCalendars: true,
      }
    }
  },

  computed: {
    totalRecords() {
      return this.records && this.records.meta ? this.records.meta.total : 0
    }
  },

  watch: {
    businessSelected: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.datatable.queries.business_id !== newVal.id)
        {
          this.datatable.queries.business_id = newVal.id
          this.onQueryChange()
        }
      }
    },
  },

  mounted() {
    this.datatable.queries.search_type = 0
    this.filterDateUpdateValues()
    this.getBusinessOptions()
  },

  methods: {
    handleExport() {
      axios.get('/agencies/analytics/export', { responseType: 'blob', params: this.datatable.queries })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xlsx' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'reports.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch()
    },

    filterDateUpdateValues(range) {
      this.datatable.queries.start_at = moment(this.filter.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      this.datatable.queries.end_at = moment(this.filter.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      this.getRecords();
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getRecords()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getRecords()
    },

    onQueryChange() {
      this.$nextTick(() => {
        this.getRecords()
      });
    },

    getBusinessOptions() {
      this.$store.dispatch('business/getBusinessOptions')
        .then((businesses) => {
          this.businesses = [
            { name: 'All businesses', id: null },
            ...businesses.map(b => {
              return {
                name: b.name,
                id: b.id,
              }
            })
          ]
          this.businessSelected = this.businesses[0]
        })
        .catch(() => {
        })
    },

    getRecords() {
      this.loading = true
      this.records = [];

      this.$store.dispatch('agency/getAnalytics', this.datatable.queries)
        .then((res) => {
          this.records = res
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    refresh() {
      this.businessSelected = this.businesses[0]
      this.datatable.queries.search = null
      this.datatable.queries.search_type = 0
      this.datatable.queries.business_id = null
      this.getRecords();
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-select {
  width: 150px;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  min-width: 200px;
}
</style>